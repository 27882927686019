import {Storage} from "@/services/Storage";
import axios from "axios";
import store from "@/store";
import {responses} from "@/utils/responses";

export default {
    name: 'user',
    state: {
        currentUser: undefined,
        securities : [],
        users: [],
    },
    getters: {
        getCurrentUser: (state) => state.currentUser,

        getUsers: (state) => state.users,
        getUserByPass: (state) => async (password) => {
            state.users = await Storage.getAllUsers();
            return state.users.find(u => u.Password.toLowerCase() === password.toLowerCase());
        },

        countNonLytSoftUsers : async (state) => {
            state.users = await Storage.getAllUsers();
            return state.users.filter(user=>user.Username !== 'Admin').length;
        },

        getUserByID: (state) => async (id) => {
            state.users = await Storage.getAllUsers();
            return state.users.find(u => u.id === id);
        },
        getSecurities: (state) => state.securities,
    },
    mutations: {
        setCurrentUser: async (state, data) => {
            if (!data) {
                data = await Storage.getCurrentUser();
            }
            state.currentUser = data;
        },
        setAllSecurities: async (state, data) => {
            if (!data) data = await Storage.getAllSecurities();
            else await Storage.setAllSecurities(data);
            state.securities = data;
        },
        setAllUsers: async (state, data) => {
            if (data) {
                await Storage.setAllUsers(data)
            } else {
                data = Storage.getAllUsers();
            }
            state.users = data
        },
    },
    actions: {
        getAllUsers: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.get(franchiseTomcatApi + `employee/${store.StoreDB}/getAll`)
                .then(({data}) => {
                    context.commit('setAllUsers', data);
                    return data;
                }).catch((err) => errorHandler.tomcatError(err))
        },

        posLogin: async (context, data) => {
            let currentUser = context.getters.getUsers.find(user => user.Password.toLowerCase() === data.toLowerCase());
            if (currentUser) {
                await Storage.setCurrentUser(currentUser);
                context.commit(`setCurrentUser`, currentUser);
                return true
            }
            return false
        },

        async editEmployee(context, payload) {
            let store = await Storage.getDefaultStore();
            return axios.put(franchiseTomcatApi + `employee/${store.StoreDB}/${payload.id}`, payload)
                .then(() => context.dispatch(`getAllUsers`))
                .catch((err) => errorHandler.tomcatError(err))
        },

        createEmployee : async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(franchiseTomcatApi + `employee/${store.StoreDB}`, payload)
                .then(() => context.dispatch('getAllUsers'))
                .catch((err) => errorHandler.tomcatError(err))
        },

        forceCashUp: async () => {
            let store = await Storage.getDefaultStore();
            let currentUser = await Storage.getCurrentUser()
            return axios.get(franchiseTomcatApi + `cashup/${store.StoreDB}/forceCashup?user=` + currentUser.Username+'&pc='+localStorage.getItem('_capuid'))
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        async generateStoreOTP() {
            let store = await Storage.getDefaultStore();
            return axios.post(franchiseTomcatApi+`stores/${store.StoreDB}/generateOTP`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },

        validateSecurityPermission: async (context, security) => {
            let securities = await Storage.getAllSecurities();
            let user = await Storage.getCurrentUser();
            let store = await Storage.getDefaultStore();
            let userType = user.Type;
            let group = securities.find(sec => sec.GroupName.toLowerCase() === userType.toLowerCase());
            if (!group) {
                await responses.showWarning(`No security group found`)
                return false;
            }
            if (!group[security]) {
                return Swal.fire({
                    title: `User not Allowed to ${security}`,
                    icon : "warning",
                    text : `Input supervisor password \n To Allow ${user.Username} to ${security}`,
                    input: "password",
                    showCloseButton : true,
                    showCancelButton: true,
                    confirmButtonText: "Authorize",
                    showLoaderOnConfirm : false,
                    preConfirm: async (password) => {
                        let users = await Storage.getAllUsers();
                        let supervisor = users.find(user => user.Password.toLowerCase() === password.toLowerCase());
                        if (!supervisor) {
                            Swal.showValidationMessage(`Incorrect password`)
                            return false;
                        }

                        let supervisorType = supervisor.Type;
                        let supervisorGroup = securities.find(sec => sec.GroupName.toLowerCase() === supervisorType.toLowerCase());
                        if (!supervisorGroup[security]) {
                            Swal.showValidationMessage(`User ${supervisor.Username} not allowed to authorize ${security}`)
                            return false;
                        }
                        return {
                            allowed : true,
                            supervisor : supervisor.Username,
                            cashier : user.Username,
                        };
                    },
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        let value = result.value;
                        if (value.allowed === true){
                            let store = await Storage.getDefaultStore();
                            axios.post(serverApi+`supervisorOverrides/${store.StoreDB}`,{
                                Supervisor : value.supervisor,
                                Cashier : value.cashier,
                                Reason : security,
                            })
                        }
                        return value;
                    }
                    return false;
                });

            }
            if (security.toString().toLowerCase().includes(`void`)){
                return {supervisor : user.Username}
            }
            axios.post(serverApi+`supervisorOverrides/${store.StoreDB}`,{
                Supervisor : user.Username,
                Cashier : user.Username,
                Reason : security,
            })
            return true;
        },

        async getAllSecurities(context) {
            let store = await Storage.getDefaultStore();
            if (!store) return ;
            return axios.get(franchiseTomcatApi+`empsecurity/${store.StoreDB}/getAll`)
                .then(({data}) => context.commit(`setAllSecurities`, data))
                .catch((err) => errorHandler.tomcatError(err))
        },

    }
}
