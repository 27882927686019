<template>
  <div class="container-fluid navigation-bar p-1 px-2">

    <button @click="toggle" class="burger">
      <font-awesome-icon icon="fa-solid fa-bars"/>
    </button>

    <div class="navbar-logo"></div>
    <div class="navigation-box"></div>

    <div class="user-settings">
      <Notification />

      <a class="burger mx-4" v-if="readyOrders > 0" @click="openKitchenOrders">
        <span class="text-warning notification-badge">{{ readyOrders }}</span>
        <font-awesome-icon icon="bowl-food"/>
        <audio ref="audioPlayer" src="@/assets/notification.mp3"></audio>
      </a>

      <button @click="tawkTo" class="burger mx-3">
        <font-awesome-icon icon="fa-solid fa-question-circle"/>
      </button>

      <button @click="syncData" class="burger">
        <font-awesome-icon icon="fa-solid fa-arrows-rotate"/>
      </button>
    </div>
  </div>
</template>

<script>


import Notification from "@/components/layout/navigation/Notification";
export default {
  name: "NavigationBar",
  beforeMount() {
    this.checkReadyOrders()
  },
  mounted() {
    this.interval = setInterval(() => {
      this.checkReadyOrders();
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  components: {Notification},
  computed: {
    readyOrders() {
      return this.$store.getters.getReadyOrdersCount;
    }
  },
  props: {
    toggle: {
      type: Function,
      require: true
    },
  },
  methods: {
    syncData() {
      this.$utils.showLoading()
      return window.location.reload(true);
    },
    tawkTo() {
      return window.open('https://tawk.to/LytSupport', '_self');
    },
    checkReadyOrders() {
      return this.$store.dispatch(`checkReadyOrders`)
          .then(res => {
            try {
              document.getElementById(`interact-btn`).click();
            } catch (e) {
            }
            if (res) return this.playSound();
          });
    },
    playSound() {
      this.$refs.audioPlayer.play();
    },
    openKitchenOrders() {
      return this.$router.push({name: `kitchen-orders`})
    }
  }
}
</script>

<style scoped>
.burger {
  width: 40px;
  height: 40px;
  background: transparent;
  color: white;
  font-size: 30px;
}

.user-settings {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.notification-badge {
  position: fixed;
  margin-right: 5px;
  color: white;
  border-radius: 5px;
  padding: 1px 6px 1px 6px;
  font-size: 15px;
  opacity: 0; /* Initially hidden */
  transform: scale(0); /* Initially small size */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.burger .notification-badge {
  opacity: 1;
  transform: scale(1);
  animation: notification-pulse 1.5s infinite;
}

@keyframes notification-pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 yellow;
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 yellow;
  }
}
</style>
