import {Storage} from "@/services/Storage";
import axios from "axios";

export default {
    name: 'tax',
    state: {
        taxLabels: [],
        hasDocsSubmitted: false,
        isSmartInvoiceLive: true,
    },
    getters: {
        getTaxLabels: (state) => state.taxLabels,
        getDocSubmissionStatus: (state) => state.hasDocsSubmitted,
        getZraCategoryByLabel: (state) => (taxLabel) => {
            return state.taxLabels.find(category => category.TaxLabel.toLowerCase() === taxLabel.toLowerCase());
        }
    },
    mutations: {
        setTaxLabels: async (state, payload) => {
            if (payload) await Storage.setTaxLabels(payload);
            else payload = await Storage.getAllTaxLabels();
            state.taxLabels = payload;
        },
        setDocSubmissionStatus: (state, data) => state.hasDocsSubmitted = data,
    },
    actions: {
        getAllTaxLabels: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.get(backOfficeTomcatApi + `zra_category/${storeDB}/getAll`)
                .then(({data}) => context.commit(`setTaxLabels`, data))
                .catch(err => errorHandler.tomcatError(err))
        },
        checkZRADetails: async (context, tpin) => {
            return axios.get(franchiseTomcatApi + `AndroidLogin/TPIN/${tpin}`)
                .then(({data}) => {
                    if (data.tpin) {
                        return data;
                    } else if (data.toString().includes(`Send TPIN:`)) {
                        throw new Error(`Invalid TPIN`);
                    }
                }).catch(err => errorHandler.tomcatError(err))
        },

        searchTaxPayers: async (context, name) => {
            return axios.get(franchiseTomcatApi + `zrataxinfo/searchTaxPayer?name=${name}`)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },

        saveSmartInvoiceData: async (context, payload) => {
            let storeDB = await Storage.getCurrentStoreDB();
            return axios.post(franchiseTomcatApi + `zra_monitoringinfo/${storeDB}`, payload)
                .then(({data}) => context.commit(`setSmartInvoicingStatus`, data))
                .catch(err => errorHandler.tomcatError(err))
        },

        getFranchiseStoresByGroup: async () => {
            let storeDetails = await Storage.getCurrentStore();
            let group = storeDetails.Group;
            return axios.get(backOfficeTomcatApi + `stores/getByGroup?group=` + group)
                .then(({data}) => data)
                .catch(err => errorHandler.tomcatError(err))
        },
        makePaymentForSmartInvoiceSignUp: async (context, payload) => {
            const apiEndpoint = ticketApi + 'smartInvoiceOnboard/makeSmartInvoicePayment';
            let DB = await Storage.getCurrentStoreDB();
            // DB = window.btoa(DB);
            let user = await Storage.getCurrentUser();
            const obj = {
                ...payload,
                paidBy: user.Username
            };
            return axios.post(apiEndpoint, obj, {
                headers: {DB}
            }).catch(err => errorHandler.tomcatError(err))
        },
        submitSmartInvoiceSignUpDocs: async (context, payload) => {
            let user = await Storage.getCurrentUser();
            let username = user.Name + " " + user.Surname;
            const apiEndpoint = ticketApi + 'smartInvoiceOnboard/submitDocs';
            const data = {
                ...payload,
                submittedBy: username,
                platform: 'Online Back-Office',
            };
            let DB = await Storage.getCurrentStoreDB();
            DB = window.btoa(DB);
            return axios.post(apiEndpoint, data, {
                headers: {DB}
            }).catch(err => errorHandler.tomcatError(err))
        },
        checkIfStoreDocsSubmitted: async (context) => {
            let DB = await Storage.getCurrentStoreDB();
            DB = window.btoa(DB);
            return axios.get(ticketApi + `smartInvoiceOnboard/checkDocumentSubmissionStatus`, {
                headers: {DB}
            }).then(({data}) => context.commit(`setDocSubmissionStatus`, data))

        },

        getCommitmentForm: async (context, payload) => {
            let DB = payload.DB
            return axios.post(synergyApi + `store-registration/export-pdf/smart-invoice-commitment-form`, payload, {
                headers: { DB },
                responseType: `blob`,
            }).then(async ({data}) => {
                let base64 = await getBase64(data);
                base64 = stripMimeType(base64);
                return base64;
            })
        },
        getBranchForm: async (context, payload) => {
            let DB = payload.DB
            return axios.post(synergyApi + `store-registration/export-pdf/branch-details`, payload,{
                headers: { DB },
                responseType: `blob`,
            }).then(async ({data}) => {
                let base64 = await getBase64(data);
                base64 = stripMimeType(base64);
                return base64;
            })
        },
    }
}
