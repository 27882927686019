import {Storage as Store} from '@capacitor/storage';

export const ZRA_DETAILS = 'zra_details'
export const DEFAULT_STORE = 'default_store'
export const ALL_STORES = 'all_stores'
export const FRANCHISE_SETTINGS = 'franchise_settings'
export const REGISTRATION_DETAILS = 'registration_details'

export const ADMIN = 'admin'
export const CATEGORIES = 'categories'
export const AllUsers = 'all_users'
export const AllSecurities = 'securities'
export const CURRENT_STORE = 'currentStore'
export const CurrentUser = 'current_user'
export const STOCK = 'stock'
export const PAYMENT_METHODS = 'payment_methods'
export const STOCK_SETTING = 'stock_setting'

export const HAS543 = 'has543'
export const HASKAZANG = 'hasKazang'
export const KAZANGSESSION = 'kazangSession'
export const KAZANGPRODUCTS = 'kazangProductList'

export const FLOAT = 'float'
export const DEVICE_SETTINGS = 'device_settings'
export const SAVED_TABLES = 'saved_tables'

export const BASE_CURRENCY = 'base_currency'

export const DISCOUNT_SPECIALS = 'discountSpecials'
export const MULTI_SPECIALS = 'multiSpecials'
export const COMBO_SPECIALS = 'comboSpecials'

const notEmpty = (val) => {
    if (!val) return false
    return val !== ''
}
const encryptAdmin = (val) => {
    // return CryptoJS.AES.encrypt(val, 'Admin-User-Hello').toString()
    return val;
}
const decryptAdmin = (val) => {
    // return CryptoJS.AES.decrypt(val, 'Admin-User-Hello').toString(CryptoJS.enc.Utf8)
    return JSON.parse(val);
}

const getValue = async (key) => {
    const value = await Store.get({
        key: key
    })
    return JSON.parse(value.value);
}

const setValue = async (key , value) => {
    await Store.set({
        key: key,
        value: JSON.stringify(value)
    });
}

export const Storage = {
    setAdminUser: async (user) => {
        await Store.set({
            key: ADMIN, value: encryptAdmin(JSON.stringify(user))
        })
    },
    getAdminUser: async () => {
        const value = await Store.get({
            key: ADMIN
        })
        return decryptAdmin(value.value)
    },

    setAllStores : async (payload) =>{
      await Store.set({ key : ALL_STORES , value : JSON.stringify(payload) });
    },

    getAllStores : async () =>{
        const value = await Store.get({key: ALL_STORES  });
        return JSON.parse(value.value)
    },
    setCurrentStore: async (payload) => setValue(CURRENT_STORE, payload),
    getCurrentStore: async () => {
        return await getValue(CURRENT_STORE);
    },

    setFranchiseSettings : async (payload) =>{
      await Store.set({ key : FRANCHISE_SETTINGS , value : JSON.stringify(payload) });
    },

    setRegistrationDetails : async (payload) =>{
      await Store.set({ key : REGISTRATION_DETAILS , value : JSON.stringify(payload) });
    },

    getFranchiseSettings : async () =>{
        const value = await Store.get({key: FRANCHISE_SETTINGS  });
        return JSON.parse(value.value)
    },

    getRegistrationDetails : async () =>{
        const value = await Store.get({key: REGISTRATION_DETAILS  });
        return JSON.parse(value.value)
    },

    setZRADetails: async (payload) => {
        await Store.set({
            key: ZRA_DETAILS, value: JSON.stringify(payload)
        })
    },

    setCategories: async (categories) => {
        await Store.set({
            key: CATEGORIES,
            value: JSON.stringify(categories)
        })
    },
    getCategories: async () => {
        const value = await Store.get({
            key: CATEGORIES
        })
        return JSON.parse(value.value)
    },

    setHas543: async (has543) => {
        await Store.set({
            key: HAS543,
            value: JSON.stringify(has543)
        })
    },
    getHas543: async () => {
        const value = await Store.get({
            key: HAS543
        })
        return JSON.parse(value.value)
    },

    setHasKazang: async (payload) => {
        await Store.set({
            key: HASKAZANG,
            value: JSON.stringify(payload)
        })
    },

    getHasKazang: async () => {
        const value = await Store.get({
            key:HASKAZANG
        })
        return JSON.parse(value.value)
    },

    getZRADetails: async () => {
        const value = await Store.get({
            key: ZRA_DETAILS
        })
        return JSON.parse(value.value)
    },
    setDefaultStore: async (defaultStore) => {
        await Store.set({
            key: DEFAULT_STORE, value: JSON.stringify(defaultStore)
        })
    },
    getDefaultStore: async () => {
        const value = await Store.get({
            key: DEFAULT_STORE
        })
        return JSON.parse(value.value)
    },

    getCurrentStoreID: async () => {
        let store = await getValue(FRANCHISE_SETTINGS);
        if (!store) return null;
        return store.StoreId;
    },
    getCurrentStoreDB: async () => {
        let store = await getValue(DEFAULT_STORE);
        if (!store) return null;
        return store.StoreDB;
    },

    setAllUsers: async (users) => {

        await Store.set({
            key: AllUsers, value: JSON.stringify(users)
        })
    },
    getAllUsers: async () => {
        const value = await Store.get({
            key: AllUsers
        });
        return JSON.parse(value.value);
    },
    setAllSecurities: async (users) => {
        await Store.set({
            key: AllSecurities, value: JSON.stringify(users)
        })
    },
    getAllSecurities: async () => {
        const value = await Store.get({
            key: AllSecurities
        });
        return JSON.parse(value.value);
    },

    setCurrentUser: async (currentUser) => {
        await Store.set({
            key: CurrentUser, value: JSON.stringify(currentUser)
        })
    },
    getCurrentUser: async () => {
        const value = await Store.get({
            key: CurrentUser
        })
        return JSON.parse(value.value)
    }, setStock: async (stock) => {
        await Store.set({
            key: STOCK, value: JSON.stringify(stock)
        });
    },
    getAllStock: async () => {
        const value = await Store.get({
            key: STOCK
        });
        return JSON.parse(value.value);
    },
    getByPlu: async (plu) =>{
      const value = await Store.get({
          key : STOCK
      });
      let stock = JSON.parse(value.value) ?? [];
      return stock.find(item => item.PLU === plu)
    },
    getFastLine: async () => {
        const value = await Store.get({
            key: STOCK
        });
        let stock = JSON.parse(value.value) ?? [];
        return stock.filter(sc => sc.FastLine === 1);
    },
    searchStockDesc: async (query) => {
        if (notEmpty(query.category)) {
            return (await Storage.searchStock(query))
                .filter(s => s.Description.toLowerCase().includes(query.description.toLowerCase())
                    || s.PLU.toLowerCase().includes(query.description.toLowerCase())
                    || s.barcode.toLowerCase().includes(query.description.toLowerCase())
                ).sort((a, b) => {
                    return a.Description < b.Description;
                })
        }
        return (await Storage.getAllStock())
            .filter(s => s.Description.toLowerCase().includes(query.description.toLowerCase())
                || s.PLU.toLowerCase().includes(query.description.toLowerCase())
                || s.barcode.toLowerCase().includes(query.description.toLowerCase())
            ).sort((a, b) => {
                return a.Description < b.Description;
            })

    },
    searchStock: async (query) => {
        const value = await Store.get({
            key: STOCK
        });
        let stock = JSON.parse(value.value) ?? [];
        let result = [];
        if (query.category !== '' && query.subCategory !== '' && query.sub2Category !== '') {
            result = stock.filter(s => s.Department.toLowerCase() === query.category.toLowerCase())
                .filter(s2 => s2.SubDep.toLowerCase() === query.subCategory.toLowerCase())
                .filter(s3 => s3.Subdep2.toLowerCase() === query.sub2Category.toLowerCase());
        } else if (query.category !== '' && query.subCategory !== '' && query.sub2Category === '') {
            result = stock.filter(s => s.Department.toLowerCase() === query.category.toLowerCase())
                .filter(s2 => s2.SubDep.toLowerCase() === query.subCategory.toLowerCase())
        } else if (query.category !== '' && query.sub2Category === '' && query.subCategory === '') {
            result = stock.filter(s => s.Department.toLowerCase() === query.category.toLowerCase());
        }
        if (query.description) {
            return result.filter(s => s.Description.toLowerCase().includes(query.description.toLowerCase())
                || s.PLU.toLowerCase().includes(query.description.toLowerCase())
                || s.barcode.toLowerCase().includes(query.description.toLowerCase())
            )
        }
        return result.sort((a, b) => {
            return a.Description < b.Description;
        });
    },
    editStockItem: async payload => {
        const value = await Store.get({
            key: STOCK
        });
        let stock = JSON.parse(value.value);
        stock = stock.map(s => {
            if (s.PLU === payload.PLU) {
                return payload
            }
            return s;
        })
        await Storage.setStock(stock);
    },
    setFloat: async (payload) => {
        await Store.set({
            key: FLOAT, value: JSON.stringify(payload)
        })
    }, getFloat: async () => {
        const value = await Store.get({
            key: FLOAT
        })
        return JSON.parse(value.value);
    },
    setDeviceSettings : async (payload) => {
      await Store.set({
          key : DEVICE_SETTINGS,
          value : JSON.stringify(payload)
      })
    },

    getDeviceSettings: async () => {
      const value = await Store.get({
          key : DEVICE_SETTINGS
      });
      return JSON.parse(value.value);
    },
    getAllTables : async () => {
      const value = await Store.get({
          key : SAVED_TABLES
      });
      return JSON.parse(value.value);
    },

    setTables : async (payload) => {
        await Store.set({
           key : SAVED_TABLES,
           value : JSON.stringify(payload)
        });
    },

    createTable : async (payload) => {
        let value = await Store.get({
            key: SAVED_TABLES
        });
        if(!value){
            value = {
                value : JSON.stringify([]),
            }
        }
        let tables = JSON.parse(value.value);
        if (!tables){
            tables = []
        }
        tables.push(payload)
        await Store.set({
            key : SAVED_TABLES,
            value : JSON.stringify(tables)
        });
    },

    editTable : async (payload) => {
        const value = await Store.get({
            key: SAVED_TABLES
        });
        let tables = JSON.parse(value.value);
        tables = tables.map(table =>{
            if (table.SaleNum === payload.SaleNum) {
                return payload
            }
            return table;
        })
        await Store.set({
            key : SAVED_TABLES,
            value : JSON.stringify(tables)
        });
    },

    deleteTable : async (salenum) => {
        const value = await Store.get({
            key: SAVED_TABLES
        });
        let tables = JSON.parse(value.value);
        tables = tables.filter(table => table.SaleNum !== salenum);
        await Store.set({
            key : SAVED_TABLES,
            value : JSON.stringify(tables)
        });
    },

    clear: async () => Store.clear(),
    setStockSettings: async (stock) => {
        await Store.set({
            key: STOCK_SETTING, value: JSON.stringify(stock)
        });
    },
    getStockSetting: async (plu) => {
        const value = await Store.get({
            key: STOCK_SETTING
        });
        const settings =JSON.parse(value.value)
        return settings.find(set=>set.plu===plu);
    },

    setPaymentMethods: async (data) => {
        await Store.set({
            key: PAYMENT_METHODS,
            value: JSON.stringify(data)
        });
    },
    getPaymentMethods: async () => {
        const value = await Store.get({
            key : PAYMENT_METHODS
        });
        return JSON.parse(value.value);
    },

    setKazangSession: async (data) => {
        await Store.set({
            key: KAZANGSESSION,
            value: JSON.stringify(data)
        });
    },
    getKazangSession: async () => {
        const value = await Store.get({
            key : KAZANGSESSION
        });
        return JSON.parse(value.value);
    },

    setKazangProductList: async (data) => {
        await Store.set({
            key: KAZANGPRODUCTS,
            value: JSON.stringify(data)
        });
    },
    getKazangProductList: async () => {
        const value = await Store.get({
            key : KAZANGPRODUCTS
        });
        return JSON.parse(value.value);
    },

    setBaseCurrency: async (data) => {
        await Store.set({
            key: BASE_CURRENCY,
            value: JSON.stringify(data)
        });
    },
    getBaseCurrency: async () => {
        const value = await Store.get({
            key : BASE_CURRENCY
        });
        return JSON.parse(value.value);
    },

    setDiscountSpecials: async (payload) => setValue(DISCOUNT_SPECIALS , payload),
    getDiscountSpecials: async () => getValue(DISCOUNT_SPECIALS),

    setMultiSpecials: async (payload) => setValue(MULTI_SPECIALS , payload),
    getMultiSpecials: async () => getValue(MULTI_SPECIALS),

    setComboSpecials: async (payload) => setValue(COMBO_SPECIALS , payload),
    getComboSpecials: async () => getValue(COMBO_SPECIALS),
}
