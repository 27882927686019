import axios from "axios"
import {Storage} from "@/services/Storage";

export default {
    name: 'BackOffice',
    state: {},
    getters: {},    mutations: {},

    actions: {
        BackOffice : async (context , page = { name : `stock` }) => {
            let mobileUser = await Storage.getAdminUser();
            mobileUser.email = mobileUser.username;
            let store = await Storage.getDefaultStore();
            let reg = await Storage.getRegistrationDetails();
            store.CustomerID = reg.id;
            let user = await Storage.getCurrentUser();
            user = user.Password;
            let device = await Device.getDeviceId()
            let obj = { mobileUser, store, user, page , device };
            console.log({obj})
            obj = encryptObj(obj);
            return openUrl(backOfficePlatform+`?auth=`+obj);
        },
        getBackOfficeUrl :async (context , page = { name : `stock` })=>{
            let mobileUser = await Storage.getAdminUser();
            mobileUser.email = mobileUser.username;
            let store = await Storage.getDefaultStore();
            let reg = await Storage.getRegistrationDetails();
            store.CustomerID = reg.id;
            let user = await Storage.getCurrentUser();
            user = user.Password;
            let device = await Device.getDeviceId()
            let obj = { mobileUser, store, user, page , device };
            console.log({obj})
            obj = encryptObj(obj);
            return backOfficePlatform+`?auth=`+obj;
        },
    }
}
